//Accordion
@import "./accordion";
@import "./table";
@import "./buttons";
@import "./mixins/mixins";
@import "./callout";
@import "./utilities";
@import "./cards";
@import "./badge";
@import "./accordion";
@import "./matSelect";
@import "./modal";

.cdk-visually-hidden {
  display: none;
}

//Placeholders
input::placeholder {
  font-weight: 200 !important;
}
//Material Popup Container
.mat-dialog-container {
  padding: 0 !important; //default padding 24px overriden to 0px
  border-radius: 0.5rem !important;
}

.cdk-overlay-container .cdk-global-overlay-wrapper .cdk-overlay-pane {
  height: auto !important;
  max-height: unset !important;
  min-height: unset !important;
}
// Modal height issue resolved
.modal-body {
  max-height: 70vh !important;
  overflow: auto;
}

.Quick-sign-on {
  .form-control {
    color: $dark;
    background-color: $white !important;
  }
  .form-control::-webkit-input-placeholder {
    color: $dark;
  }
  .form-control:-moz-placeholder {
    color: $dark;
  }
  .form-control::-moz-placeholder {
    color: $dark;
  }
  .form-control:-ms-input-placeholder {
    color: $dark;
  }
  .form-control::-ms-input-placeholder {
    color: $dark;
  }
  .form-control:disabled,
  .form-control[readonly] {
    background-color: #eaebef;
  }
  .selected-dial-code {
    color: $dark;
  }
  .invalid-feedback {
    color: rgb(221, 131, 131);
  }
  .form-select {
    color: $dark;
    background-color: $white !important;
  }
}
.form-control-lg {
  border-radius: 2rem;
}

// Proprtional and Responsive Image wrapper

.img-wrapper {
  width: 100%;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 4/3;
  overflow: hidden;
}
