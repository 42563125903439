// Colors

//Please don't change this theme colors. This are branding colors
$primary: #008dea !default;
$secondary: #2f455c !default;
$success: #198754 !default;
$info: #0dcaf0 !default;
$warning: #fd7e14 !default;
$danger: #dc3545 !default;
$light: #f8f9fa !default;
$dark: #212529 !default;
$purple: #7357ff !default;
$indigo: #0f4b87 !default;



$input-bg: transparent !default;
//Font-family

$font-family-base: "Poppins", "Open Sans", system-ui, -apple-system, sans-serif !default;
$font-family: "Poppins", "Open Sans", system-ui, -apple-system, sans-serif !default;
$font-size-base: 0.875rem !default;

// Accordian
$accordion-button-padding-y: 0.5rem !default;

//breadcrumb style
$breadcrumb-divider-color: $light !default;
$breadcrumb-active-color: $primary !default;

//enable variable for negative margin
$enable-negative-margins: true !default;

//Spacer Variables
$spacer: 1rem !default;


$border-radius: 1rem !default;

$btn-border-radius: $border-radius * 50 !default;
$btn-border-radius-lg: $btn-border-radius !default;
/*all input control border radius*/
$input-border-radius: $border-radius !default;
$input-border-radius-lg: $border-radius !default;

// Card variables
$card-border-radius: $border-radius * 0.5 !default;

// Badge Variables
$variable-prefix: bs- !default;
$badge-bg-scale: 20% !default;
$enable-gradients: false !default;
$badge-border-scale: -10% !default;
$badge-color-scale: 40% !default;
$min-contrast-ratio: 4.5% !default;
$badge-padding-x: 1em !default;
$badge-padding-y: 1em !default;

// Accordion variables
$accordion-button-active-color: $dark !default;
$accordion-button-active-bg: $light !default;

$headings-font-weight: 700 !default;
