.badge{
    $badge-padding-y: 0.25rem !default;
    $badge-padding-x: 1rem !default;
    $badge-font-size: 1rem !default;
}

// Generate contextual modifier classes for colorizing the badge.
@each $state,
$value in $theme-colors {
    $badge-background: shift-color($value, $badge-bg-scale);
    $badge-color: shift-color($value, $badge-color-scale);
    @if (contrast-ratio($badge-background, $badge-color) < $min-contrast-ratio) {
        $badge-background: mix($value, color-contrast($badge-color), abs($badge-bg-scale));
    }
    .badge-#{$state} {
        @include badge-variant($badge-background, $badge-color);
    }
}