.mat-dialog-container {
    // scss-docs-start modal-css-vars
    --#{$prefix}modal-zindex: #{$zindex-modal};
    --#{$prefix}modal-width: #{$modal-md};
    --#{$prefix}modal-padding: #{$modal-inner-padding};
    --#{$prefix}modal-margin: #{$modal-dialog-margin};
    --#{$prefix}modal-color: #{$modal-content-color};
    --#{$prefix}modal-bg: #{$modal-content-bg};
    --#{$prefix}modal-border-color: #{$modal-content-border-color};
    --#{$prefix}modal-border-width: #{$modal-content-border-width};
    --#{$prefix}modal-border-radius: #{$modal-content-border-radius};
    --#{$prefix}modal-box-shadow: #{$modal-content-box-shadow-xs};
    --#{$prefix}modal-inner-border-radius: #{$modal-content-inner-border-radius};
    --#{$prefix}modal-header-padding-x: #{$modal-header-padding-x};
    --#{$prefix}modal-header-padding-y: #{$modal-header-padding-y};
    --#{$prefix}modal-header-padding: #{$modal-header-padding}; // Todo in v6: Split this padding into x and y
    --#{$prefix}modal-header-border-color: #{$modal-header-border-color};
    --#{$prefix}modal-header-border-width: #{$modal-header-border-width};
    --#{$prefix}modal-title-line-height: #{$modal-title-line-height};
    --#{$prefix}modal-footer-gap: #{$modal-footer-margin-between};
    --#{$prefix}modal-footer-bg: #{$modal-footer-bg};
    --#{$prefix}modal-footer-border-color: #{$modal-footer-border-color};
    --#{$prefix}modal-footer-border-width: #{$modal-footer-border-width};
    // scss-docs-end modal-css-vars
}
.modal-header, .modal-footer {
    background-color: $gray-200;
}

.modal-body {
    background-color: $light;
}